import { Link } from "gatsby"
import React from "react"
import "./LayoutComponentStyle.scss"
import PWAPrompt from "../PWAPrompt/PWAPrompt"

const LayoutComponent = ({
  children,
  isEnglish,
  languageSwitchSubpage,
}: {
  children: any
  isEnglish?: boolean
  languageSwitchSubpage: string
}) => {
  return (
    <div className="layout-component-wrapper">
      {children}
      <div className="layout-footer-wrapper">
        <div>
          <ul>
            <li>
              {isEnglish ? (
                <Link to="/en/derby/#training">Training schedule</Link>
              ) : (
                <Link to="/derby/#training">Trainingszeiten</Link>
              )}
            </li>
            <li className="a-small-amount-of-margin-top">
              {isEnglish ? (
                <Link to="/en/archiv">Archive</Link>
              ) : (
                <Link to="/archiv">Archiv</Link>
              )}
            </li>
            <li className="a-small-amount-of-margin-top">
              {isEnglish ? (
                <Link to="/en/presse">Press</Link>
              ) : (
                <Link to="/presse">Presse</Link>
              )}
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li>
              <Link to={"/" + languageSwitchSubpage}>DE</Link>
            </li>
            <li>
              <Link to={"/en/" + languageSwitchSubpage}>EN</Link>
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li>
              {isEnglish ? (
                <Link to="/en/contact">Contact</Link>
              ) : (
                <Link to="/contact">Kontakt</Link>
              )}
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li>
              {isEnglish ? (
                <Link to="/en/impressum">Imprint</Link>
              ) : (
                <Link to="/impressum">Impressum</Link>
              )}
            </li>
            <li className="a-small-amount-of-margin-top">
              {isEnglish ? (
                <a
                  href="https://mtv-ludwigsburg.de/datenschutz/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy
                </a>
              ) : (
                <a
                  href="https://mtv-ludwigsburg.de/datenschutz/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Datenschutz
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>
      <PWAPrompt />
    </div>
  )
}

export default LayoutComponent
