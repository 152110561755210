import React, { useState } from "react"
import "./MenuComponentStyle.scss"
import BCRDLogo from "../../assets/image/logo.png"
import { Link } from "gatsby"
import Bars from "../../assets/icon/bars.svg"
import Close from "../../assets/icon/close.svg"

const MenuComponent = ({
  selected,
  isEnglish,
  submenu,
}: {
  selected: number
  isEnglish?: boolean
  submenu?: { title: string; url: string }[]
}) => {
  const [showMenu, toogleMenu] = useState<boolean>(false)
  const moveToInMobile = (id: string) => {
    if (document && id) {
      document.documentElement.scrollTop =
        document.getElementById(id.replace("#", "")).offsetTop - 250
    }
  }

  return (
    <div className="menu-component-wrapper">
      <div className="desktop-menu">
        <img src={BCRDLogo} alt="BCRD Logo" />

        <div>
          <ul className="desktop-full-menu">
            <li>
              <Link
                to={isEnglish ? "/en" : "/"}
                className={selected === 1 ? "active" : ""}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to={isEnglish ? "/en/team" : "/team"}
                className={selected === 2 ? "active" : ""}
              >
                Team
              </Link>
            </li>
            <li>
              <Link
                to={isEnglish ? "/en/derby" : "/derby"}
                className={selected === 3 ? "active" : ""}
              >
                Rollerderby
              </Link>
            </li>
            {false && (
              <li>
                <a
                  href="#"
                  className={selected === 4 ? "active" : ""}
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  ONLINESHOP
                </a>
              </li>
            )}
            <li>
              {isEnglish ? (
                <Link
                  to="/en/contact"
                  className={selected === 5 ? "active" : ""}
                >
                  Contact
                </Link>
              ) : (
                <Link to="/contact" className={selected === 5 ? "active" : ""}>
                  Kontakt
                </Link>
              )}
            </li>
          </ul>

          <ul className="desktop-navigation-submenu">
            {submenu &&
              submenu.map((value, index) => {
                return (
                  <li key={index}>
                    <a
                      href={value.url}
                      target={
                        value.url.toLocaleLowerCase().includes("https://") ||
                        value.url.toLocaleLowerCase().includes("http://")
                          ? "_blank"
                          : ""
                      }
                      rel={
                        value.url.toLocaleLowerCase().includes("https://") ||
                        value.url.toLocaleLowerCase().includes("http://")
                          ? "noreferrer nofollow"
                          : ""
                      }
                    >
                      {value.title}
                    </a>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>

      <div className="mobile-menu">
        <div className="item-wrapper">
          <img src={BCRDLogo} alt="BCRD Logo" />
        </div>
        <div className="item-wrapper">
          <span onClick={() => toogleMenu(!showMenu)}>
            <Bars />
          </span>
        </div>

        {submenu && (
          <ul className="mobile-navigation-submenu">
            {submenu.map((value, index) => {
              return (
                <li key={index}>
                  {value.url.toLocaleLowerCase().includes("https://") ||
                  value.url.toLocaleLowerCase().includes("http://") ||
                  value.url.toLocaleLowerCase().startsWith("/") ? (
                    <a
                      href={value.url}
                      target={
                        value.url.toLocaleLowerCase().startsWith("/")
                          ? ""
                          : "_blank"
                      }
                      rel={
                        value.url.toLocaleLowerCase().startsWith("/")
                          ? ""
                          : "noreferrer nofollow"
                      }
                    >
                      {value.title}
                    </a>
                  ) : (
                    <a
                      onClick={() => {
                        moveToInMobile(value.url)
                      }}
                    >
                      {value.title}
                    </a>
                  )}
                </li>
              )
            })}
          </ul>
        )}

        {showMenu && (
          <div className="mobile-links-menu-wrapper">
            <div>
              <span onClick={() => toogleMenu(!showMenu)}>
                <Close />
              </span>
            </div>
            <ul>
              <li>
                <Link
                  to={isEnglish ? "/en/" : "/"}
                  className={selected === 1 ? "active" : ""}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to={isEnglish ? "/en/team" : "/team"}
                  className={selected === 2 ? "active" : ""}
                >
                  Team
                </Link>
              </li>
              <li>
                <Link
                  to={isEnglish ? "/en/derby" : "/derby"}
                  className={selected === 3 ? "active" : ""}
                >
                  Rollerderby
                </Link>
              </li>
              {false && (
                <li>
                  <a
                    href="#"
                    className={selected === 4 ? "active" : ""}
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    ONLINESHOP
                  </a>
                </li>
              )}
              <li>
                <Link
                  to={isEnglish ? "/en/contact" : "/contact"}
                  className={selected === 5 ? "active" : ""}
                >
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default MenuComponent
