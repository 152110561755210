import React from "react"
import Loadable from "@loadable/component"

const PWAPrompt = () => {
  return (
    <IosPwaPrompt
      copyTitle="Als App installieren"
      copyBody="Diese Homepage kann als App installiert und genutzt werden"
      copyShareButtonLabel="1) Wählen Sie das Teilen-Menü"
      copyAddHomeButtonLabel='2) Installieren Sie via "Zum Home-Bildschirm"'
      copyClosePrompt="Schließen"
    />
  )
}

const IosPwaPrompt = Loadable(() => import("react-ios-pwa-prompt"))

export default PWAPrompt
